import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lozad from 'lozad';
import remark from 'remark';
import html from 'remark-html';
import markdown from 'remark-parse';
import highlight from 'remark-highlight.js';
import report from 'vfile-reporter';
import { isBrowser } from '../../api';
import 'highlight.js/styles/monokai-sublime.css';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ''
    };
  }

  componentDidMount() {
    // lazy loads elements with default selector as '.lozad'
    // Prevent WebPack build fail
    if (isBrowser()) {
      // Initialize library
      const observer = lozad('.lozad', {
        load(el) {
          /* eslint-disable no-param-reassign */

          el.src = el.dataset.src;
          el.onload = () => {
            el.classList.add('animated');
            el.classList.add('fadeIn');
          };
          /* eslint-enable */
        }
      });
      observer.observe();
    }
    const { post } = this.props;
    remark()
      .use(markdown)
      .use(highlight)
      .use(html)
      .process(post, (err, file) => {
        console.error(report(err || file));
        this.setState({ content: String(file) });
      });
  }

  render() {
    const { content } = this.state;
    return (
      <div
        className="post-content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
        style={{
          padding: 30,
          background: 'white'
        }}
      />
    );
  }
}

Content.propTypes = {
  post: PropTypes.string.isRequired
};

export default Content;
